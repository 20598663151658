<template>
    <div class='mobile-menu'>
        <div style='margin-top: 10px;' id='menu_container'>

            <div v-for='item in menu_items.menu_items' :key="item.id">
                <div class='menu-item-container'  @click='selectMenuItem(item)' v-if='checkVisible(item)'>
                    <div v-show='selected_menu == item.id' class='selected-item'></div>
                    <div class='menu-item'>
                
                        <div class='menu-icon' :class="item.icon"></div> 
                        <div>{{item.name}}</div>
                        <div v-show='item.has_sub_menu'>
                            <i class="fa-solid fa-angle-right ms-2"></i>
                        </div>
                        
                    </div>
                </div>
                
            </div>

             <div class='menu-item-container' @click='signOut'>
                <div class='menu-item'>
                    <div class='menu-icon fa-light fa-arrow-right-from-bracket'></div>
                    <div>Sign Out</div>
                </div>
            </div>

        </div>

        <div class='sub-menu'>

            <div @click='goBack' class='go-back'>
                <i class="fa-light fa-angle-left"></i> Go back
            </div>

            <div v-for='item in sub_menu_items' :key="item.id" >
                
                <div class='menu-item-container' @click='selectMenuItem(item)' v-if='checkVisible(item)' >
                    <div v-show='selected_menu == item.id' class='selected-item'></div>
                    <div class='menu-item'>
                            <span class='menu-icon' :class="item.icon"></span> <span>{{item.name}}</span><span v-show='item.has_sub_menu'><i class="fa-solid fa-angle-down ms-2"></i></span>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import menuItems from "./menuItems.json";
import {checkAuth, checkUserAccess} from "@/modules/functions.js";

export default {
    props:['user_info'],
    data(){
        return{
            menu_items: [],
            sub_menu_items: [],
            selected_menu: 0
        }
    },
    methods:{
        selectMenuItem(item){
            if(!item.has_sub_menu){
                this.sub_menu_expand = false;
                this.selected_menu = item.id;
                document.querySelector('.mobile-menu').classList.toggle('is-open');

                this.$router.push(item.route);

            }
            else{
                this.sub_menu_expand = true;
                this.sub_menu_items = item.sub_menu_items;
                document.querySelector('.sub-menu').classList.toggle('is-open');
            }
        },
        goBack(){
            document.querySelector('.sub-menu').classList.toggle('is-open');
        },
        async signOut() {
                let response = await fetch(`/api/logout`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {'Content-Type': 'application/json'}
                });

                let results = await checkAuth(response);

                if (response.status === 200) {
                    window.location.href = results;
                }
        },
        checkVisible(item){
            if(typeof item.visible_to !== 'undefined'){
                if(item.visible_to == 'All'){
                    return true;
                }

                return checkUserAccess(this.user_info.user_role, item.visible_to);
            }
        }
    },
    mounted(){
        this.menu_items = menuItems;
    }
}
</script>

<style lang='scss' scoped>

.mobile-menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 98;
	padding-top: 6rem;
    background-color:var(--eerie-black);
	color: var(--white);
	transform: translateY(-100%);
	transition: 0.3s;
    transition: transform 330ms ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
}

.mobile-menu.is-open {
	transform: translateY(0);
    transition: 0.7s;
    transition: transform 330ms ease-in-out;
}

.menu-item-container{
    padding-left: 10px;
    overflow: hidden;
    cursor:pointer;
    height: 60px;
    line-height: 60px;

     &:hover {
        background-color: var(--side-bar-menu-hover);
        
        .selected-item{
            background-color: var(--eerie-black);
        }
    }
}

.menu-icon{
    flex-basis: 20px;
}

.menu-item{
    margin-left: 20px;
    font-size: 1.2rem;
    white-space: nowrap; 
    display:flex; 
    gap: 15px; 
    align-items:center;
}

.selected-item{
    width: 5px; 
    height: 40px; 
    margin-top: 10px;
    background-color: var(--primary-colour-4);
    position: absolute;
    border-radius: 5px;
}

.sub-menu{
    position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 98;
	padding-top: 6rem;
    background-color:var(--eerie-black);
	color: var(--white);
	transform: translateX(-100%);
	transition: 0.3s;
    transition: transform 330ms ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
}

.sub-menu.is-open {
	transform: translateX(0);
    transition: 0.7s;
    transition: transform 330ms ease-in-out;
}

.go-back{
    font-size: 1.2rem;
    cursor: pointer;
    margin-left: 25px;
    margin-top: 40px;
    margin-bottom: 30px;
}
</style>