<template>
    <div>
        <div id="sidebarMenu" class='sidebar' :class='{big: menu_expanded}'>
            <div class="sidebar-expand-section" id='sidebar_expand_icon' @click='expandMenu'>
                <span v-show='!menu_expanded'><i class="fa-solid fa-angles-right"></i></span>
                <span v-show='menu_expanded'><i class="fa-solid fa-angles-left" ></i></span>
            </div>

            <div style='margin-top: 50px;' id='sidebar_container'>
                <div v-for='item in menu_items' :key="item.id" >
                    <div class='menu-item-container' v-if='checkVisible(item)' @click='selectMenuItem(item)'>

                        <div v-show='selected_menu == item.id' class='selected-item'></div>
                        <div class='menu-item'>
                            <div v-show='menu_expanded' class='item-full-name'>
                                <span class='menu-icon' :class="item.icon"></span> <span class='item-text'>{{item.name}}</span><span v-show='item.has_sub_menu'><i class="fa-solid fa-angle-down ms-2"></i></span>
                            </div>
                            <div v-show='!menu_expanded'>
                                <span class='menu-icon' :class="item.icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id='sidebar_sub_menu' class='sidebar-sub-menu' :class='{big: sub_menu_expand}'>
            <div style='margin-top: 50px;'>
                <div class='sub-menu-title'>{{sub_menu_title}}</div>
                <div style='margin-left: 30px; margin-right: 30px;'><hr></div>
                <div v-for='item in sub_menu_items' :key="item.id" >
                    <div class='sub-menu-item-container' @click='selectMenuItem(item)' v-if='checkVisible(item)'>
                        <div v-show='selected_sub_menu == item.id' class='selected-sub-item'></div>
                        <div class='menu-item'>
                            <div class='item-full-name'>
                                <span class='menu-icon' :class="item.icon"></span> <span>{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import menuItems from "./menuItems.json";
import {checkUserAccess} from "@/modules/functions.js";

export default {
    props: ['user_info'],
    emits:['expandedSideBarListener'],
    data(){
        return{
            menu_expanded: false,
            sub_menu_expand: false,
            menu_items: [],
            sub_menu_items: [],
            selected_menu: 0,
            clicked_parent_menu: 0,
            sub_menu_title: '',
            selected_sub_menu: 0
        }
    },
    methods:{
        expandMenu(){
            this.menu_expanded = !this.menu_expanded;
            this.$emit('expandedSideBarListener', this.menu_expanded);
        },
        selectMenuItem(item){
            if(!item.has_sub_menu){
                this.sub_menu_expand = false;
                this.selected_menu = item.id;
                this.selected_sub_menu = 0;

                if(typeof item.sub_menu_item !== 'undefined'){
                    this.selected_menu = this.clicked_parent_menu;
                    this.selected_sub_menu = item.id;
                }

                this.$router.push(item.route);

            }
            else{
                this.sub_menu_expand = true;
                this.sub_menu_items = item.sub_menu_items;
                this.clicked_parent_menu = item.id;
                this.sub_menu_title = item.name;
            }
        },
        checkVisible(item){
            if(typeof item.visible_to !== 'undefined'){
                if(item.visible_to == 'All'){
                    return true;
                }

                return checkUserAccess(this.user_info.user_role, item.visible_to);
            }
        },
        setSelectedDefault(){
            let current_route = window.location.pathname;
            let found_sub_menu = false;

            for (let i = 0; i < this.menu_items.length; i++) {
                let current_menu_item = this.menu_items[i];

                if(current_menu_item.route == current_route){
                    this.selected_menu = current_menu_item.id;
                    break;
                }

                if(typeof current_menu_item.sub_menu_items !== 'undefined'){
                    for (let k = 0; k < current_menu_item.sub_menu_items.length; k++) {
                        let current_sub_menu_item = current_menu_item.sub_menu_items[k];

                       if(current_sub_menu_item.route == current_route){
                            this.selected_menu = current_menu_item.id;
                            this.selected_sub_menu = current_sub_menu_item.id;
                            found_sub_menu = true;
                            break;
                       }
                    }
                }
                
                if(found_sub_menu){
                    break;
                }
            }
        }
    },
    mounted(){
        this.menu_items = menuItems.menu_items;
      
        setTimeout(() => {
            this.setSelectedDefault();
        }, 1);

        const sidebar_sub_menu = document.getElementById("sidebar_sub_menu");
        const sidebar_container = document.getElementById("sidebar_container");
        const sidebar_expand_icon = document.getElementById("sidebar_expand_icon");

        document.addEventListener("click", (event) => {
            const inside_sidebar_sub_menu = sidebar_sub_menu.contains(event.target);
            const sidebar_click = sidebar_container.contains(event.target);
            const sidebar_expand_icon_click = sidebar_expand_icon.contains(event.target);
            
            setTimeout(() => {
                if (!inside_sidebar_sub_menu && !sidebar_click && !sidebar_expand_icon_click &&sidebar_sub_menu.className.includes('big')){
                    this.sub_menu_expand = false; //If clicking outside the container and it's open then close the container
                }
            }, 50);

        });

        window.onpopstate = (event) =>{ //Loading page from a back button event
            if(event){
                this.setSelectedDefault();
                return;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.sidebar{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    background: var(--eerie-black);
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;  
    width: var(--sidebar-width);
    transition: width var(--sidebar-expand-transition-time);

    @media (max-width: 767.98px) {
		display: none;
	}
}

.sidebar-sub-menu{
    width: 0px; 
    height: 100vh; 
    background-color: var(--eerie-black); 
    position: fixed;
    z-index: 1050;
    top: 0;
    transition: width var(--sidebar-expand-transition-time);
    background: var(--eerie-black);
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;  
    overflow-y: auto;
}

.sidebar-expand-section{
    width: 100%;
    height: 30px;
    margin-left: 20px;
    font-size: 1.3rem;
    padding-top: 30px;
    cursor: pointer;
}

.sidebar.big {
	width: var(--sidebar-width-expanded);

    @media (max-width: 768px) {
       width: 0px;
    }
}

.sidebar-sub-menu.big {
	width: var(--sidebar-width-expanded);

    @media (max-width: 768px) {
       width: 0px;
    }
}

.menu-item-container{
    padding-left: 10px;
    overflow: hidden;
    cursor:pointer;
    height: 60px;
    line-height: 60px;

     &:hover {
        background-color: var(--side-bar-menu-hover);
        
        .selected-item{
            background-color: var(--eerie-black);
        }

        .menu-icon{
            color: var(--eerie-black);
        }

        .item-text{
            color: var(--eerie-black);
        }
    }
}

.sub-menu-item-container{
    padding-left: 10px;
    margin-left: 20px;
    overflow: hidden;
    cursor:pointer;
    height: 45px;
    line-height: 45px;

     &:hover {
        background-color: var(--side-bar-menu-hover);
        
        .selected-sub-item{
            background-color: var(--eerie-black);
        }
    }
}

.sub-menu-title{
    font-size: 1.3rem;
    white-space: nowrap; 
    margin-left: 35px;
}

.menu-item{
    margin-left: 15px;
    -webkit-user-select: none;       
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
}

.item-full-name{
    white-space: nowrap; 
}

.selected-item{
    width: 5px; 
    height: 40px; 
    margin-top: 10px;
    background-color: var(--primary-colour-4);
    position: absolute;
    border-radius: 5px;
}

.selected-sub-item{
    width: 5px; 
    height: 30px; 
    margin-top: 9px;
    background-color: var(--secondary-colour-3);
    position: absolute;
    border-radius: 5px;
    margin-left: -5px;
}

.menu-icon{
    font-size:1.3rem;
    width: 35px;
    margin-left: -8px;
    color: white;
}

.sidebar-sub-menu, .sidebar{
    overflow-y:auto; 
    overflow-x:hidden; 
    scrollbar-width: thin; //Firefox only
    scrollbar-color: darkgrey slategrey; //Firefox only
}

.sidebar-sub-menu::-webkit-scrollbar, .sidebar::-webkit-scrollbar { //Chrome, Edge, and Safari 
  width: 0.5rem;
}
 
.sidebar-sub-menu::-webkit-scrollbar-track, .sidebar::-webkit-scrollbar-track { //Chrome, Edge, and Safari 
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
 
.sidebar-sub-menu::-webkit-scrollbar-thumb, .sidebar::-webkit-scrollbar-thumb { //Chrome, Edge, and Safari 
  background-color: darkgrey;
  border-radius: 10px;
}

.item-text{
    color:white;
}
</style>