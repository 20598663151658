import Router from '../router'; 

export function checkSQLerrors(data){	
  
    if (typeof data.sql_errors !== 'undefined'){
        console.log(`%c ${new Date().toLocaleString()}: SQL ERROR `, 'background: red; color: white; font-size:1.2rem;');
        throw 'Invalid SQL';
    }
}

export async function checkAuth(response){

    if (response.status == 200){
        let data = await response.json();
        checkSQLerrors(data);
        return data;
    }

    if (response.status == 403){
        Router.push('/access-denied');
        return
    }

    if (response.status == 401){
        let data = await response.json();
        switch(data.message){
            case 'Token not found.':
                Router.push('/login');
                break;

            case 'page restricted':
                Router.push('/access-denied');
                break;

            case 'not logged in':
                Router.push('/login');
                break;
        }
        
        return;
    }
}

export function openModal(id, options = {}){
    let modal = new bootstrap.Modal(document.getElementById(id), options);
    modal.show();
}

export function closeModal(id){
    let modal = bootstrap.Modal.getInstance(document.getElementById(id)) //get existing instance created by the openModal function
    if(modal != null){
        modal.hide();
    }
}

export function formatNumber(number, dp=2){
    if (isNaN(number)){
        number = 0;
    }
    return parseFloat(number).toFixed(dp);
}

export function shallowCopy(object){
    return Object.assign({}, object);
}

export function validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function inputMasks(){
    let allowed_keys = ['Enter', 'Backspace','Delete','Shift', 'Control', 'CapsLock', 'Tab', 'Alt', 'Escape', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'F5'];

    //Only Numbers in field
    document.querySelectorAll('[onlyNumber]').forEach((element) => {
        element.addEventListener('keydown', e =>{
            let reg = new RegExp(/[^0-9]+/g);
            if (!allowInputEditingKeys(e)){
                if (!allowed_keys.includes(e.key)){
                    if (reg.test(e.key)){
                        e.preventDefault();
                    }
                }
            }
        });
    });


    document.querySelectorAll('[onlyNumberAllowNegative]').forEach((element) => {
        element.addEventListener('keydown', e =>{
            let reg = new RegExp(/[^-?0-9]+/g);
            if (!allowInputEditingKeys(e)){
                if (!allowed_keys.includes(e.key)){
                    if (reg.test(e.key)){
                        e.preventDefault();
                    }
                }
            }
        });
    });

    //Only decimal in field
    document.querySelectorAll('[onlyDecimal]').forEach((element) => {
        element.addEventListener('keydown', e =>{
            let reg = new RegExp(/[^0-9.]+/g);
            if (!allowInputEditingKeys(e)){
                if (!allowed_keys.includes(e.key)){
                    if (reg.test(e.key)){
                        e.preventDefault();
                    }
                }
            }
            if (e.target.value.includes('.')){ //Only allow 1 decimal place.
                if (e.key == '.'){
                    e.preventDefault();
                }
            }
        });
    });

    //Allowed key combos e.g. cut, copy, paste
    function allowInputEditingKeys(e){
        if (typeof e.key !== 'undefined'){
            let key = e.key.toLowerCase();
    
            if (e.ctrlKey && key == 'a' ||
                e.ctrlKey && key == 'x' ||
                e.ctrlKey && key == 'c' ||
                e.ctrlKey && key == 'v'){
                return true
            }
        }
    }
  
}

export function resetPageNumbers(pagination_data){
    pagination_data.current_page = 1;
    pagination_data.starting_row_number = 0;
}

export function checkUserAccess(current_role, expected_role) {
    if(typeof expected_role === 'string'){
        if(current_role == expected_role){
            return true;
        }
    }

    if(typeof expected_role === 'object'){
        for (let i = 0; i < expected_role.length; i++) {
           if(expected_role[i] == current_role){
                return true;
           }
        }
    }

    return false;
}

export function filterCustomerDropdowns(groups, fascias, outlets){
    fascias.filtered = fascias.all;
    groups.filtered =groups.all;
    outlets.filtered =outlets.all;
    outlets.filtered_searchable = outlets.all.map((x)=>{
        return {
            value: x.ID,
            label: x.formatted_outlet_text
        }
    })
    // outlets.filtered = [];

    if(fascias.selected == 'all'){
        const timer = ms => new Promise(res => setTimeout(res, ms))
    
        let start = 0;
        let chunk_amount = 250;
    
        async function load(){
            for (let i = 0; i < outlets.all.length / chunk_amount; i++) {
               
                if(fascias.selected == 'all'){
           
                    if(i != 0){
                        outlets.filtered.pop();
                    }
            
                    outlets.filtered = outlets.filtered.concat(outlets.all.slice(start, start+chunk_amount));
                    outlets.filtered = outlets.filtered.concat([{'ID': 0, formatted_outlet_text: 'Loading...'}]);
                    
                    start += chunk_amount;
            
                    if(i == (outlets.all.length / chunk_amount) -1){
                        outlets.filtered.pop();
                    }
            
                    await timer(300);
                }
            }
        }
        
        if(outlets.all.length > chunk_amount){
            load();
        }
        else{
            outlets.filtered = outlets.all;
        }
    }
    else{
        if(fascias.selected != ''){
            outlets.filtered = outlets.all;
        }
    }
    
    if(groups.selected != ''){
        fascias.filtered =fascias.all.filter(x=>x.GroupName == groups.selected);

        if(fascias.selected == ''){
            outlets.filtered = outlets.all.filter((x)=>{

                let found_outlets =fascias.filtered.some((y)=>{
                    if(y.ID == x.ParentCustomerId){
                        return x;
                    }
                })

                return found_outlets;
            });

            outlets.filtered_searchable = outlets.filtered.map((x)=>{
                return {
                    value: x.ID,
                    label: x.formatted_outlet_text
                }
            });
        }
    }

    if(fascias.selected != '' && fascias.selected != 'all'){
        outlets.filtered =outlets.all.filter(x=>x.ParentCustomerId ==fascias.selected);

        outlets.filtered_searchable = outlets.filtered.map((x)=>{
            if(x.ParentCustomerId == fascias.selected){
                return {
                    value: x.ID,
                    label: x.formatted_outlet_text
                }
            }
        });
    }
}