import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '@/css/tradeledger.css'
import '../node_modules/@fortawesome/fontawesome-pro/js/all.min.js'

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

createApp(App).use(router).mount('#app')
