<template>

</template>

<script>
import { createApp } from "vue";
import Toast from "vue-toastification";

createApp().use(Toast, {
    transition: "Vue-Toastification__fade",
    containerClassName: "alert-container",
});

import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

export default {
    props: ['alert_queue'],
    alert_queue(){
        return{
            toast: null
        }
    },
    mounted(){
        this.toast = useToast();
    },
    watch:{
          'alert_queue': {
            handler(){
                const alert = this.alert_queue[this.alert_queue.length - 1];
                this.toast(alert.text, { timeout: 2500 ,  
                position: "bottom-right", 
                type: alert.type, 
                toastClassName: "my-custom-toast-class",
                hideProgressBar: true,
            });
            },
            deep: true
        }
    }
}
</script>

<style lang='scss'>
    .Vue-Toastification__toast--success.my-custom-toast-class {
        background-color: #419b53;

    }
    .Vue-Toastification__toast--error.my-custom-toast-class {
        background-color: #D0342C;
    }

    .Vue-Toastification__container.bottom-right.alert-container{
        // margin-bottom: var(--footer-height);

        @media (max-width: 768px) { 
            padding-left: 30px;
            padding-right: 30px;
        }
    }

</style>