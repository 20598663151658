<template>
    <button @click='handleButtonClick' type="button" class="btn btn-dark" :class="size + ' ' + margin">{{text}}</button>
</template>

<script>
export default{
    props: {
        text: {},
        size: {default: 'auto'},
        margin: ''
    },
    methods: {
        handleButtonClick(){
           this.$emit('buttonOnClick');
        }
    }
}
</script>

<style lang='scss' scoped>
.btn{
    border-radius: 14px;
    background-color: var(--button-colour);
    border-color: var(--button-colour);
}
.btn:focus,
.btn.focus {  
  text-decoration: none;
  border-color: transparent;
  box-shadow: none;
}

.sm{
    width: 89px;
	height: 43px;
}
.lg{
   	height: 40px;
	padding: 0 25px;
    width: 10rem;
}
.auto{
    width: auto;
    padding: 0 25px;
    height: 40px;
}

.btn:disabled{
    color: var(--light-gray-2);
}

</style>