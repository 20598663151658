import moment from "moment";
import _ from "underscore";

let Common = {
    methods:{
        getFormatDate: function (date) {
			if(date != "")
				return moment(new Date(date)).format('DD MMM YYYY');
			else
				return date;			
        },
		getPostFormatDate: function(date) {
			if(date != "")
				return moment(new Date(date)).format('YYYY-MM-DD');
			else
				return date;
		},
        getSum(object, field){
            return _.reduce(object, function(obj, val) { return obj + parseFloat(val[field])}, 0); 
        },	
        formatNumber(number, dp=2){
            if (isNaN(number)){
                number = 0;
            }
            return parseFloat(number).toFixed(dp);
        },
        currencyNumberFormat(number) {
            let num = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(number);
            return num;
        },
        updateFooterValue(footer, row_id, column_id, value_to_change, new_value){
            footer.filter(x=>x.id == row_id)[0].data.filter(x=>x.id== column_id)[0][value_to_change] = new_value;
        },
    }
};

export default Common;