<template>
	<div v-if="user_info">
	    <div id="main-container" :class='{"big":menu_expanded, "main-container":isLoggedIn, "main-full-container":!isLoggedIn}'>

            <div style='display: flex; flex-direction: column; min-height: 100vh; margin:0;'>
                <MobileMenu :user_info="user_info" v-if="isLoggedIn"/>
                <Sidebar :user_info="user_info" v-if="isLoggedIn" @expandedSideBarListener='expandedSideBar' />
                <Header :user_info="user_info" v-if="show_header" :menu_expanded='menu_expanded' :isLoggedIn='isLoggedIn' />
        
                <div class="main-body">
                    <TopAlertMessage :message='user_info.news_message' v-if='show_top_message'/>
                    <router-view :key="$route.fullPath" class="page-content" v-bind:user_info="user_info" :abort_controller='abort_controller'/>
                </div>

                <Footer />
            </div>
        </div>
    </div>
</template>

<script>
	import Header from '@/components/layouts/Header'
	import Sidebar from '@/components/layouts/menus/Sidebar'
	import MobileMenu from '@/components/layouts/menus/MobileMenu'
	import Footer from '@/components/layouts/Footer'
	import TopAlertMessage from '@/components/layouts/TopAlertMessage'
    import {checkAuth} from "@/modules/functions.js";
    import { useRouter } from "vue-router"

    export default {
        name: 'App',
        components : {Footer, Header, Sidebar, TopAlertMessage, MobileMenu},
        data() {
            return {
                show_header: true,
                show_top_message: true,
                user_info: null,
				isLoggedIn: false,
                menu_expanded: false,
                abort_controller: null
            }
        },
        watch: {
            $route(to, from) {
                this.show_header = true;
                let hide_header = ['Auth0Login'];
                let hide_top_message = ['Login', 'Auth0Login'];
  
                if (hide_header.includes(to.name)) {
                    this.show_header = false;
                }

                if (hide_top_message.includes(to.name)) {
                    this.show_top_message = false;
                }
            }
        },
        methods: {
            async getData(){
                let response = await fetch(`/api/user-info`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {'Content-Type': 'application/json'},
                });
                
                let results = await checkAuth(response);
                if (response.status === 200){

                    document.title = results.tab_title;

                    this.user_info = results

                    this.isLoggedIn = (this.$route.name == 'SessionExpired' || this.$route.name == 'Auth0Login')?false:results.is_loggedin

                    if(this.isLoggedIn) {
                        if(window.location.pathname === "/" || window.location.pathname === "/login") {
                            window.location.href = "/dashboard"
                        }
                    }
                }

                if (response.status === 401){
                    this.user_info = {}
                }
            },
            async getTheme(){
                let response = await fetch(`/api/theme`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {'Content-Type': 'application/json'},
                });
                
                let results = await checkAuth(response);

                if (response.status === 200){
                    document.documentElement.className = results.theme;
                }
            },
            expandedSideBar(menu_expanded){
                this.menu_expanded = menu_expanded;
            },
            abortApiRequestsBeforeRouterPush(){
                this.abort_controller = new AbortController();
                
                const router = useRouter();

                router.beforeEach((to, from, next) => {
                    this.abort_controller.abort();
                    this.abort_controller = new AbortController(); //New instance as will have been used up when aborted
                    next();
                });
            }
        },
        mounted() {
            this.getData()
            this.abortApiRequestsBeforeRouterPush();
        },
        beforeMount(){
            this.getTheme();
        }
    }
</script>

<style lang="scss">

.main-container {
	width: calc(100% - var(--sidebar-width));
	margin-left: var(--sidebar-width);
	transition: margin-left var(--sidebar-expand-transition-time), width var(--sidebar-expand-transition-time);
} 

.main-full-container {
	width: 100%;
	margin-left: 0px;
} 

 .main-container.big {
	width: calc(100% - var(--sidebar-width-expanded));
	margin-left: var(--sidebar-width-expanded);
}

@media (max-width: 768px) {
    .main-container.big {
        width: 100%;
        margin-left: 0px;
    }

    .main-container {
        width: 100%;
        margin-left: 0px;
    }
} 

.main-body {
	width: 100%;
	min-height: calc(100vh - var(--header-height) - var(--footer-height));
	padding: 30px 20px 100px 30px;
	position: relative;
    // margin-top: var(--header-height); //Only if fixed header
}

.page-content{
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    
    @media (max-width: 768px) {
        padding-right: 0.2rem;
        padding-left: 0.2rem;
    }
}

</style>