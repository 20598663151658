<template>
    <div v-show='message' class="top-message-bar">
       <i class="fa-light fa-bullhorn top-alert-icon"></i><span class="top-alert-message" id="topAlertMessage">{{message}}</span>
    </div>
</template>
<script>
export default {
    props:['message']
}
</script>

<style lang="scss" scoped>
    .top-message-bar{
        background-color: #ffa8a8;
        border-radius: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 1.2rem;
        text-align: center;
        font-weight: 800;
        margin-bottom: 10px;
        margin-left: 2rem;
        margin-right: 2rem;
	}	
    .top-alert-icon{
        font-size: 1.6rem;
    }
    .top-alert-message{
        margin-left: 10px;
    }
</style>