<template>
	<div class="footer-container">
        <div class="content">
            <div>
                <div class='title'>Services</div>
                <div><a class='link-text' href="https://commercialonlineorders.majestic.co.uk/drink-responsibly.html">Drink Responsibly</a></div>
                <div><a class='link-text' href="https://commercialonlineorders.majestic.co.uk/privacy-cookies.html">Privacy & Cookies</a></div>
            </div>
            <div>
                <div class='title'>About us</div>
                <div><a class='link-text' href="https://commercialonlineorders.majestic.co.uk/about.html">About Us</a></div>
                <div><a class='link-text' href="https://commercialonlineorders.majestic.co.uk/contact-us.html">Contact Us</a></div>
            </div>
        </div>
	</div>
</template>

<style lang='scss' scoped>
.footer-container {
	background-color: #F2F2F2;
	height: var(--footer-height);
	padding: 0 27px;
	font-size: var(--font-size-xs);
	bottom: 0;
	left: 0;
	width: 100%;
}
.content{
    margin-top: 30px;
    display: flex;
    gap: 80px;
    margin-left: 30px;
}
.title{
//    font-family: ProximaNova Bold, arial, verdana;
    font-weight: 600;
    color: #134A2C;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.link-text{
    // font-family: ProximaNova Regular, arial, verdana;
    font-size: 16px;
    line-height: 28px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    @media (max-width: 390px) {
        font-size: 12px;
        line-height: 24px;
    }
}
</style>