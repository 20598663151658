<template>
<div>
	<header class="navbar shadow top-nav-bar fixed-nav-bar" :class='{"menu-expanded":menu_expanded}'>
		<div class="navbar-logo">
			<div><img class="header-logo" src="@/assets/logos/Majestic-logo.svg"/></div>
		</div>

           <button v-if='isLoggedIn' class="hamburger" @click='showMobileMenu'>
            <div>
                <i class="fas fa-bars"></i>
            </div>
                <!-- <div v-if="hamburger=='menu'">
                </div>
                <div v-else>
                    <i class="fas fa-times"></i>
                </div> -->
            </button>

		<div v-if='isLoggedIn' class="nav-right-buttons">
			<div class="logout-container">
				<!-- <div class="email-label">{{user_email}}</div>
				<div class="rank-label">{{full_name}}</div> -->
                <div class='logged-in-as'>Logged in as {{comapny_name}}</div>
			</div>
            <div class='logout-button' @click='signOut'><i class="fa-solid fa-arrow-turn-down-left"></i></div>
		</div>

	</header>
</div>   
</template>

<script>
    import {useRoute} from "vue-router";
    import {checkAuth} from "@/modules/functions.js";
	import Common from "../../common";

    export default {
        name: 'Header',
        props: ['user_info', 'menu_expanded', 'isLoggedIn'],
		mixins: [Common],
        data() {
            return {
                user_email: '',
                show_alert: true,
                full_name: '',
                user_id: 0,
                comapny_name: '',
                hamburger: 'menu'
            }
        },
        methods: {
            async signOut() {
                let response = await fetch(`/api/logout`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {'Content-Type': 'application/json'}
                });

                let results = await checkAuth(response);

                if (response.status === 200) {
                    window.location.href = results;
                }
            },
			showMobileMenu() {
                const mobile_menu = document.querySelector('.mobile-menu');
                this.hamburger = (this.hamburger == 'menu' ? 'close' : 'menu');
                mobile_menu.classList.toggle('is-open');
			},
            async checkLoggedInUser(){
                let response = await fetch(`/api/check-logged-in-user?user_id=${this.user_info.user_id}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {'Content-Type': 'application/json'}
                });

                let results = await checkAuth(response);

                if (response.status === 200) {
                    if(results.different){
                        location.reload();
                    }
                }
            }
        },
        mounted() {
            if(this.user_info) {
                let userInfo = this.user_info
                this.user_email   = userInfo.user_email
                this.full_name    = userInfo.first_name+" "+userInfo.last_name;
                this.comapny_name = this.full_name;
                if(userInfo.user_role == 'Customer') {
                    this.comapny_name = userInfo.account_details.account_name + '(' + userInfo.account_details.account_no + ')';
                }                     
            }
        },
        watch:{
            $route (to, from){
                if (typeof from.name !== 'undefined'){
                    this.checkLoggedInUser();
                }
            }
        } 
    }
</script>
<style lang='scss' scoped>

    .top-nav-bar{
        background-color: var(--top-nav-bar-background-colour);
        padding: 0 20px;
        z-index: 100;
    }
	.logout-button{
	    cursor: pointer;
        font-size: 2.2rem;
        color: #495057;
        float: left;
	}
	.logout-container {
		margin-right: 30px;
		text-align: right;
        float: left;
	}
	.logout-container .email-label {
		color: var(--eerie-black);
		font-family: var(--font-family-inter);
		font-size: 15px;
		font-style: normal;
		font-weight: 500;	
	}
	.logout-container .rank-label {
		color: var(--eerie-black);
		font-family: var(--font-family-inter);
		font-size: var(--font-size-xs);
		font-style: italic;
		font-weight: 300;
	}
	.navbar-logo {
  		padding-top: .75rem;
		padding-bottom: .75rem;
		font-size: 1rem;
		background-color: rgba(0, 0, 0, .25);
		box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
		background: none;
 		box-shadow: none;
        margin-left: 20px;
	}		

    .main-container, .navbar {
        height: var(--header-height);
    }

.nav-right-buttons {
	margin-right: 40px;

    @media (max-width: 767.98px) {
		display: none;
	}
}

.navbar .navbar-toggler {
	top: .25rem;
	right: 1rem;

    @media (max-width: 767.98px) {
        top: 1.25rem;
    }
}

.logged-in-as{
    margin-top: 15px;
    font-size: 1rem;
}
.header-logo {
    float: left;
    width: 390px;

    @media (max-width: 1200px){
        width: 300px;
    }
    
    @media (max-width: 768px){
        width: 230px;
    }

}

.hamburger {
	appearance: none;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
	display: block;
}

.hamburger div {
    color:#495057;
	font-size: 2rem; 
}

@media (min-width: 768px) {
	.hamburger {
		display: none;
	}
}

</style>