<template>
    <div>
        <Toast :alert_queue='alert_queue'/>

        <div class="main-div">
            <transition name='fade' mode="out-in">
                <div v-if="status == 'login'">
                    <h1 class="heading-text">
                        Welcome to <br> Tradeledger - Majestic
                    </h1>
                    <p class="p-text p-lastLine">Log in to manage your accounts</p>
                    <div class='row'>
                        <div class='col-md-6' v-show="is_show">
                            <div class="form-group mt-4">
                                <input type="text" @keyup.enter="handleSubmit" class="form-control login-textbox" v-model="prefix" placeholder="Prefix" >
                            </div>
                        </div>
                        <div class='row'>
                            <div class='col-md-6'>
                                <div class="form-group mt-4">
                                    <input type="email" @keyup.enter="handleSubmit" class="form-control login-textbox" v-model="username" placeholder="Username">
                                </div>
                                <div class="form-group mt-4">
                                    <input type="password"  @keyup.enter="handleSubmit" class="form-control login-textbox" v-model="password" placeholder="Password">
                                </div>
                            </div>
                        </div> 
                        <div class='row mt-4'>
                            <div class='m-auto'>
                                <div>
                                    <Button text="Login" @click="login"/> 
                                </div>
                            </div>
                        </div>
                        <div class='mt-4 forgot-password' @click='forgotPassword'>Forgot Password?</div>
                    </div>
                </div>
                <div v-else-if='status == "forgot_password"'>
                    <h3 class="heading-text">Reset Password</h3>
                    <p class='p-text p-color mt-3'>If you have forgotten your password, please enter your email address below and you will be sent a password reset email.</p>
                    <div class='row'>
                        <div class='col-md-6'>
                            <div class="form-group mt-4">
                                <input type="email" @keyup.enter="handleSubmit" class="form-control login-textbox" v-model="email_for_reset_password" placeholder="Email">
                            </div>
                        </div>
                    </div>
                    <div class='row mt-4'>
                        <div class='m-auto'>
                            <div>
                                <Button text="Reset Password" @click="resetPassword"/> 
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if='status == "reset_password_successful"'>
                    <h3 class="heading-text">Verify Password Reset</h3>
                    <p class='p-text p-color mt-3 px-2'>A password reset email has been sent to {{email_for_reset_password}}.<br>Please check your inbox and follow the instructions to reset your password</p>
                    <div class='row mt-4'>
                        <div class='m-auto'>
                            <div>
                                <Button class='button' text="Return To Login" @click='backToLogin()'/>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>

</template>

<script>
import "particles.js";
import {checkAuth} from '@/modules/functions.js';
import Button from '@/components/buttons/Button.vue'
import Toast from '@/components/popups/Toast.vue';

export default {
    components: {Button, Toast},
    data() {
            return {
				username: '',
				password: '',
				prefix: 'itim',
                errors: {},
                alert_queue: [],
                is_show : false,
                status : 'login',
                email_for_reset_password: '',
            }
        },    
    methods : {
        async login() {
            let response = await fetch(`/api/user-login`, {
                    method: 'POST',
                    credentials: "include",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: this.username,
                        password: this.password,
                        prefix: this.prefix
                    })
                });

                let results = await response.json();

                this.errors = {};
                if (results.errors.length == 0){
                    location.href = results.header;
                }
                else{
                    this.errors = results.errors;
                        for (const key of Object.keys(results.errors)) {
                        this.alert_queue.push({type : 'error', text: results.errors[key]});
                    }
                }
        },
        handleSubmit(e) {
            e.preventDefault()
            this.login() 
        },
        forgotPassword() {
            this.status = 'forgot_password';
        },
        async resetPassword(){
            let response = await fetch(`/api/send-reset-password`, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                 body: JSON.stringify({
                    email : this.email_for_reset_password
                }),     
            });

            let results = await checkAuth(response);
         
            if (response.status === 200) {
                if (results.errors.length == 0){
                    this.status = 'reset_password_successful';
                }
                else{
                    this.errors = results.errors;
                }
            }
        },
        backToLogin(){
            this.status = 'login';
        }    
    },
    beforeMount(){
    }  
}

</script>
<style lang='scss' scoped>
.previous-button-container{
    float: left; margin-left: 10px; margin-top:-25px;
}

.exit-button-container{
float: right; margin-right: 10px; margin-top: -25px;
}

.icons{
font-size: 2rem; color: grey; cursor: pointer;
}

.message-container{
    padding-left: 50px; 
    padding-right: 50px; 
    padding-top: 50px; 
    padding-bottom: 50px;

    @media (max-width: 767.98px) {
        padding-left: 0px; 
        padding-right: 0px; 
    }
}

.particles-full-screen {
    width: 100%;
	height: 100%;
	position: fixed;
	z-index: -10;
	top: 0;
	left: 0;
    background-image: url('@/assets/background_5.jpg');
    background-repeat: no-repeat; 
    background-size: cover; 
    background-attachment: fixed; 
    background-position: center center;
}

.form-control:focus {
  border-color: #74c0fc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(34, 139, 230, 0.6);
}

.phone-number{
    margin-left: 6px; 
    margin-right: 6px; 
    margin-top: 3px; 
    display: inline-block;
    color: #343a40;
    font-size: 0.85rem;
  }

  .login-container {
	height: 100%;
	width: 60%;
	background-color: var(--white);
	border-radius: 2rem;
	margin: auto;
	margin-top: 3%;
	-webkit-box-shadow: 5px 5px 40px rgba(255, 255, 255, 0.301);
	box-shadow: 5px 5px 40px rgba(255, 255, 255, 0.301);
	padding: 40px 0 20px 0;
	margin-bottom: -2%;
	min-height: 450px;
    max-width: 1000px;

    @media (max-width: 1400px) {
        width: 100%;
    }

  }
  .separator{
    color:#004687;
  }

  .main-div {
    padding-left: 35px;
    margin-top: 50px;
    margin-bottom: 24px;
    @media (max-width: 767px) {
        padding-left: 0px;
    }
  }

  .heading-text {
    font-family: ProximaNova Bold, arial, sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 900;
    line-height: 68px;
    letter-spacing: 0em;
    text-align: left;
    color: #283533;
    margin-bottom: 35px;

    @media (max-width: 767px) {
        font-size: 40px;
        line-height: 43px;
    }
    @media (max-width: 380px) {
        font-size: 35px;
        line-height: 40px;
    }

  }

  .p-text {
    font-family: ProximaNova Regular, arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: left;
  }

  .p-color {
    color: #6E8227;
    opacity: 1;
  }

  .p-firstLine {
    margin-bottom: 8px;
  }
  .p-lastLine {
    font-size: 18px;
    line-height: 15px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.6;
  }

  .login-textbox {
    max-width: 350px;
  }

  .forgot-password{
    font-family: ProximaNova Regular, arial, sans-serif;
    color: #1C1C1C;
    font-size: 11px;
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
  }
</style>
